import React from "react";

function Spacer({ space }) {
  const spacerData = {
    space_01: "2px",
    space_02: "4px",
    space_03: "8px",
    space_04: "16px",
    space_05: "32px",
    space_06: "64px",
    space_07: "128px",
    space_08: "256px"

  };
  return <div style={{ marginBottom: spacerData[space] }}></div>;
}

export default Spacer;
