import React from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";

const LayoutHOC = (Component) =>
  function WrappedComponent(props) {
    return (
      <div>
        <header className="header-container">
          <NavBar />
        </header>
        <main className="main-container">
          <Component {...props} />
        </main>
        <Footer/>
      </div>
    );
  };

export default LayoutHOC;
