import React from "react";
import "./styles.css";

const Footer = () => {
  return (
    <footer class="footer">
      <p className="text-white-50">2024 © Jawanib Transit. Develop By SynthAdam Labs </p>
    </footer>
  );
};

export default Footer;
