import React, { useState } from "react";

const ContactForm = () => {
  const baseData = {
    customerName: "",
    email: "",
    message: ""
  };
  const [msgData, setMsgData] = useState(baseData);

  const handleSubmit = () => {
    const { customerName, email, message } = msgData;
    if (customerName && email && message) {
      alert(`Thanks for sharing your message ${customerName}`);
      setMsgData(baseData);
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-head">Get in Touch</h2>
      <input
        type="text"
        name="customerName"
        id="customerName"
        placeholder="Enter Your Name ..."
        className="text-box"
        value={msgData.customerName}
        onChange={(e) =>
          setMsgData({ ...msgData, customerName: e.target.value })
        }
      />
      <input
        type="text"
        name="email"
        id="email"
        placeholder="Enter Your Email ..."
        className="text-box"
        style={{ width: "90%" }}
        value={msgData.email}
        onChange={(e) => setMsgData({ ...msgData, email: e.target.value })}
      />
      <textarea
        name="message"
        id="message"
        placeholder="Enter Message ..."
        className="text-area"
        rows={5}
        value={msgData.message}
        onChange={(e) => setMsgData({ ...msgData, message: e.target.value })}
      />
      <button
        type="button"
        className="book-btn btn"
        style={{ marginBottom: 0 }}
        onClick={handleSubmit}
        disabled
      >
        Send Message
      </button>
    </div>
  );
};

export default ContactForm;
