import CarImage1 from "../assets/images/jawanib-transit-1.png";
import CarImage2 from "../assets/images/jawanib-transit-2.png";
import CarImage3 from "../assets/images/jawanib-transit-3.png";
import CarImage4 from "../assets/images/jawanib-transit-4.png";
import CarImage5 from "../assets/images/jawanib-transit-5.png";
import CarImage6 from "../assets/images/jawanib-transit-6.png";
import CarImage7 from "../assets/images/jawanib-transit-7.png";
import CarImage8 from "../assets/images/jawanib-transit-8.png";
import CarImage9 from "../assets/images/jawanib-transit-9.png";
import CarImage10 from "../assets/images/jawanib-transit-10.png";





const serviceData = [
  {
    title: "Business Converge",
    image:
      "https://img.freepik.com/free-photo/handshaking-after-interview_1098-744.jpg?size=626&ext=jpg&ga=GA1.1.462957766.1704556508&semt=ais",
    subTitle:
      "Elevate corporate meets with Business Converge—where seamless professionalism converges for productive gatherings, creating an atmosphere of collaboration and success.",
    details:
      "Elevate your corporate meetings with Business Converge, where professionalism meets seamless coordination for productive and impactful gatherings."
  },
  {
    title: "Executive Elite Transfers",
    image:
      "https://img.freepik.com/free-photo/luxurious-car-parked-highway-with-illuminated-headlight-sunset_181624-60607.jpg?size=626&ext=jpg&ga=GA1.1.462957766.1704556508&semt=sph",
    subTitle:
      "Experience unparalleled luxury with VIP & VVIP Transfers. Our service ensures elite travel, reflecting prestige and comfort for a journey tailored to your distinct requirements.",
    details:
      "Experience the pinnacle of luxury with our VIP & VVIP Transfer service, ensuring elite travel that reflects prestige and unparalleled comfort."
  },
  {
    title: "Enterprise Concierge",
    image:
      "https://img.freepik.com/free-photo/elegant-uber-driver-giving-taxi-ride_23-2149241767.jpg?size=626&ext=jpg&ga=GA1.1.462957766.1704556508&semt=ais",
    subTitle:
      "Tailored for corporate needs, Enterprise Concierge provides a premium service that encompasses hassle-free logistics and personalized attention, ensuring a seamless and efficient business experience.",
    details:
      "Tailored for corporate needs, Enterprise Concierge provides a premium service delivering hassle-free logistics and personalized attention for your business requirements."
  },
  {
    title: "Premiere Escapes",
    image:
      "https://img.freepik.com/free-photo/positive-man-with-upped-hands-near-embracing-women-car-beach_23-2148039074.jpg?size=626&ext=jpg&ga=GA1.1.462957766.1704556508&semt=ais",
    subTitle:
      "Immerse yourself in glamour and excitement with Premiere Escapes. Our Entertainment Trips are curated for unforgettable experiences, adding a touch of exclusivity to your special events.",
    details:
      "Immerse yourself in glamour and excitement with Premiere Escapes, offering curated Entertainment Trips for unforgettable experiences at special events."
  },
  {
    title: "Urban Excursions",
    image:
      "https://img.freepik.com/premium-photo/tourist-couple-city_23-2147828122.jpg?size=626&ext=jpg&ga=GA1.1.462957766.1704556508&semt=ais",
    subTitle:
      "Uncover the city's heart with Urban Excursions—a City Tour service blending culture, history, and entertainment. Immerse yourself in an informative and engaging journey through urban landscapes.",
    details:
      "Discover the heart of your city with Urban Excursions - our City Tour service blends culture, history, and entertainment for an immersive urban experience."
  },
  {
    title: "MetroMover",
    image:
      "https://img.freepik.com/free-photo/mumbai-skyline-skyscrapers-construction_469504-21.jpg?size=626&ext=jpg&ga=GA1.1.462957766.1704556508&semt=sph",
    subTitle:
      "Experience swift and comfortable Intercity Transfers with MetroMover, connecting you seamlessly to your destination. Enjoy a refreshed travel experience as you move effortlessly between cities.",
    details:
      "Swift and comfortable Intercity Transfers are made easy with MetroMover, connecting you seamlessly to your destination for a refreshed travel experience."
  },
  {
    title: "Skyport Shuttle",
    image:
      "https://t4.ftcdn.net/jpg/00/65/30/25/240_F_65302562_xEmok1vsJL54A8tPXgs8BZiMNpFFYm8M.jpg",
    subTitle:
      "Make Airport Transfers stress-free with Skyport Shuttle, ensuring a smooth and punctual ride to or from the airport. Start or end your journey with convenience and comfort.",
    details:
      "Skyport Shuttle ensures stress-free Airport Transfers, offering a smooth and punctual ride to or from the airport for a convenient travel experience."
  },
  {
    title: "Occasion Odyssey",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzqGR1P5oEjWk7TXP9jH3nWatNdOVfg_1koHLZuOuvQYuTud6QGuR2RcUkyECTeSuT_Xw&usqp=CAU",
    subTitle:
      "Transform your special moments with Event Trips by Occasion Odyssey. Our service is dedicated to curating unforgettable experiences, turning each trip into a memorable chapter in your life's journey.",
    details:
      "Transform your special moments with Event Trips by Occasion Odyssey, dedicated to curating unforgettable experiences that turn each trip into a memorable chapter in your life's journey."
  }
];


const carData = [
  {
    carName: "GMC Yokon",
    details: "",
    imageURL: CarImage1
  },
  {
    carName: "Toyota Prado",
    details: "",
    imageURL: CarImage2
  },
  {
    carName: "Suburban",
    details: "",
    imageURL: CarImage3
  },
  {
    carName: "Tahoe",
    details: "",
    imageURL: CarImage4
  },
  {
    carName: "Ford Taurus",
    details: "",
    imageURL: CarImage5
  },
  {
    carName: "BMW 7Series",
    details: "",
    imageURL: CarImage6
  },
  {
    carName: "Mercedes V Class",
    details: "",
    imageURL: CarImage7
  },
  {
    carName: "Mercedes S Class",
    details: "",
    imageURL: CarImage8
  },
  {
    carName: "Lexus Sedan",
    details: "",
    imageURL: CarImage9
  },
  {
    carName: "Coaster Bus",
    details: "",
    imageURL: CarImage10
  }
];

const reviewData = [
  {
    review:
      "Outstanding Service! From booking to arrival, every step was seamless. The attention to detail and professionalism truly stood out. Highly recommended for a stress-free travel experience.",
    customerName: "John Doe",
    customerInfo: "Frequent Traveler"
  },
  {
    review:
      "Exceptional! Our event was a great success, thanks to the dedicated team and their impeccable service. The vehicles were top-notch, and the entire journey was comfortable and enjoyable. Will definitely choose them again!",
    customerName: "Jane Smith",
    customerInfo: "Event Planner"
  },
  {
    review:
      "Five Stars! Our city tour was made memorable by the excellent service provided. Courteous staff, punctual transfers, and a fleet of well-maintained vehicles. A truly satisfying experience that exceeded expectations.",
    customerName: "Robert Johnson",
    customerInfo: "Local Explorer"
  }
];

const companyInfo = `Founded in 2015 by visionary Fawaz Hamoud M. Alshehri, Jawanib Transportation EST has transcended into a distinguished leader within the tours and travels industry. Our enterprise boasts a versatile fleet, offering an extensive array of transportation solutions tailored to diverse needs.

At the heart of our operations lies an unwavering commitment to excellence. Jawanib Transportation EST takes immense pride in delivering journeys that seamlessly combine safety and comfort. Our seasoned drivers, steeped in experience and training, not only ensure a smooth ride but prioritize the safety and well-being of every passenger.

Guided by the pillars of safety, comfort, flexibility, and professionalism, we stand as a beacon of trust for those seeking reliable transportation services. Our commitment to customer satisfaction has elevated Jawanib Transportation EST as the preferred choice for individuals and groups alike, promising a travel experience that is not only seamless but also thoroughly enjoyable. Experience the epitome of transportation excellence with Jawanib Transportation EST.`;

export { serviceData, carData, reviewData, companyInfo };
