import React from "react";
import QTImage from "../../assets/images/qoate.png";

function Card({ title, subTitle, addInfo, image, variant, bookFleet }) {
  if (variant === "type-1") {
    return (
      <div className="card-container">
        <img src={image} alt="" srcset="" className="card-image" />
        <h2 className="card-title">{title}</h2>
        <p className="card-subtitle">{subTitle}</p>
        {/* <button className="nav-menu-btn card-button">
        More <span className="more-sign">→</span>
      </button> */}
      </div>
    );
  } else if (variant === "type-2") {
    return (
      <div className="card-container-2">
        <img src={image} alt={title} srcset="" className="card-image-2" />
        <div className="card">
          <h2 className="card-title">{title}</h2>
          <button
            type="button"
            className="nav-menu-btn card-subtitle-2"
            onClick={() => bookFleet(title)}
          >
            Book Now
          </button>
        </div>
        {/* <button className="nav-menu-btn card-button">
        More <span className="more-sign">→</span>
      </button> */}
      </div>
    );
  } else if (variant === "review") {
    return (
      <div className="card-container-3">
        <div className="topSec-3">
          <img src={QTImage} alt="" srcset="" className="card-image-3" />
          <p className="card-subtitle-3">{title}</p>
        </div>
        <div className="bottomSec-3">
          <div className="avatar"></div>
          <h2 className="card-title-3">{subTitle}</h2>
          <p className="card-subtitle-4">{addInfo}</p>
        </div>
      </div>
    );
  }
}

export default Card;
