import React from "react";
import {
  serviceData,
  carData,
  reviewData,
  companyInfo
} from "../../Utils/baseData";
import Car1Image from "../../assets/images/car-1.svg";
import AboutImage from "../../assets/images/20945765.jpg";
import ContactImage from "../../assets/images/contact-img.png";
import Spacer from "../../Components/Spacer";
import Card from "../../Components/Card";
import ContactForm from "../../Components/ContactForm";
import "./styles.css";

function Home(props) {
  const moveToFleet = () => {
    const section = document.getElementById("fleet");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const bookFleet = (fleetName) => {
    // const fleet = fleetName.replace(/ /g, "%20");
    const baseURL =
      "https://api.whatsapp.com/send/?phone=+966556634063&text=Enquiry:%20";
    window.location.href = `${baseURL}${fleetName}`;
  };

  return (
    <>
      {/* Home Section */}
      <section id="home" className="hero-1-bg bg-light banner-container">
        <div className="caption-cont">
          <section className="caption-left">
            <h1 className="hello">Hello,</h1>
            <h1 className="main-capt">
              Driving Your Success: Jawanib Transit,
              <br /> Your Business Boost Partner
            </h1>
            <p className="sub-capt">
              Welcome to Jawanib Transit, where the charm of Riyadh meets
              seamless travel. Experience the city in comfort and style as we
              redefine transportation.
            </p>
            <button className="book-btn btn" onClick={moveToFleet}>
              {" "}
              Book Your Fleet Now
            </button>
          </section>
          <img src={Car1Image} alt="" className="caption-right" />
        </div>
      </section>
      {/* Services Section */}
      <section id="services" className="services-cont bg-light">
        <h2 className="service">Services</h2>
        <p className="service-sub">
          Revolutionize your business travel with Jawanib Transit's tailored
          solutions <br />– from swift airport transfers to reliable corporate
          transportation.
        </p>
        <Spacer space="space_06" />
        <div className="service-card-container">
          <div className="card-collection">
            {serviceData.map((item) => (
              <Card
                image={item.image}
                title={item.title}
                subTitle={item.subTitle}
                variant="type-1"
              />
            ))}
          </div>
        </div>
      </section>
      <section id="fleet" className="fleet-cont">
        <h2 className="service">Travel Fleet</h2>
        <p className="service-sub">
          Travel with the best. Our fleet ensures superior comfort and
          reliability, making every journey exceptional.
          <br /> Your destination, our commitment.
        </p>
        <Spacer space="space_06" />
        <div className="service-card-container">
          <div className="card-collection">
            {carData.map((item) => (
              <Card
                image={item.imageURL}
                title={item.carName}
                variant="type-2"
                bookFleet={bookFleet}
              />
            ))}
          </div>
        </div>
      </section>
      <section id="reviews" className="review-cont bg-light">
        <h2 className="service">What Our Customers Say</h2>
        <p className="service-sub">
          Customer Voices: See why they love us. Explore testimonials sharing
          exceptional experiences.
          <br /> Your satisfaction is our pride.
        </p>
        <Spacer space="space_07" />
        <div className="review-card-container">
          <div className="card-collection">
            {reviewData.map((item) => (
              <Card
                title={item.review}
                subTitle={item.customerName}
                addInfo={item.customerInfo}
                variant="review"
              />
            ))}
          </div>
        </div>
      </section>

      <section id="about-us" className="us-cont">
        <h2 className="service">Discover Jawanib</h2>
        <Spacer space="space_07" />
        <div className="about-container">
          <img src={AboutImage} alt="" className="about-image" />
          <p className="about-data ">{companyInfo}</p>
        </div>
      </section>
      <section id="contact" className="contact">
        <h2 className="service">Ring Our Bell</h2>
        <p className="service-sub">
          Ring Our Bell for instant connection! 🛎️ Your direct line to seamless
          assistance and a friendly chat awaits.
          <br /> Let's stay connected on your journey with us!
        </p>
        <Spacer space="space_06" />
        <div className="contact-container">
          <ContactForm />
          <div className="contact-right-sec">
            <img src={ContactImage} alt="" className="contact-image" />
            <h2 className="contact-image-subtext">
              Need a Travel Partner?
              {/* Searching for Your Ideal Travel Companion? */}
            </h2>
            <p className="service-sub">
              Rev up your travels with our ground crew! Smooth rides, endless
              smiles.
              <br />
              Ready to cruise with us?
            </p>

            <a
              href="https://api.whatsapp.com/send/?phone=+966556634063&text=Enquiry:%20Hi%20Jawanib%20Transit"
              className="chat-btn"
            >
              Start Live Chat
            </a>
            <p className="alternate">Or you can contact at</p>
            <div className="badge-cont">
              <a href="mailto:info@jawanibtransit.com" className="badge">
                <b>Email:</b> info@jawanibtransit.com
              </a>
              <a href="tel:+966556634063" className="badge">
                <b>Phone:</b> +966 556634063
              </a>

              <div className="badge addr-bagde">
                <b>Address:</b> Southern Ring Road, Al Manfooha 6337,
                Riyadh-Kingdom of Saudi Arabia
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
