import React, { useState } from "react";

const navMenus = [
  { label: "Home", link: "home" },
  { label: "Services", link: "services" },
  { label: "Fleet", link: "fleet" },
  { label: "Reviews", link: "reviews" },
  { label: "About Us", link: "about-us" },
  { label: "Contact us", link: "contact" }
];

function NavBar(props) {
  const [activeMenu, setActiveMenu] = useState("home");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const scrollToSection = (e, sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setActiveMenu(sectionId);
      setMobileMenuOpen(false); // Close mobile menu after clicking on a menu item
    }
  };

  return (
    <div className="nav-container">
      <div className="nav-logo">Jawanib Transit</div>
      <div className="nav-menu-container">
        {/* Desktop Menu */}
        <div className="desktop-menu">
          {navMenus.map((menu) => (
            <button
              key={menu.link}
              className={
                activeMenu === menu.link
                  ? "nav-menu-btn active"
                  : "nav-menu-btn"
              }
              onClick={(e) => scrollToSection(e, menu.link)}
            >
              {menu.label}
            </button>
          ))}
        </div>

        {/* Mobile Menu (Hamburger menu) */}
        <div className="mobile-menu">
          <button className="hamburger-btn" onClick={toggleMobileMenu}>
            ☰
          </button>
          {mobileMenuOpen && (
            <div className="mobile-menu-items">
              {navMenus.map((menu) => (
                <button
                  key={menu.link}
                  className={
                    activeMenu === menu.link
                      ? "nav-menu-btn active"
                      : "nav-menu-btn"
                  }
                  onClick={(e) => scrollToSection(e, menu.link)}
                >
                  {menu.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
